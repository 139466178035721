'use client'

import React from 'react'

import Link from 'next/link'

import { useTranslation } from '@/lib/i18n'
import { Box, Divider, IconButton, Drawer as MuiDrawer, Typography } from '@mui/material'

import { DrawerIcon } from './drawer-icon'

import { Center } from '../center'
import { LegalEntityIcon, LicenceContractIcon, MapIcon, ShopIcon } from '../icons'
import { EVLogoIcon } from '../icons/ev-logo-icon'

import config from '../../config'
import { useLanguage } from '../../hooks/use-language'
import { DRAWER_WIDTH, HEADER_HEIGHT } from '../../theme'

export const SideDrawer: React.FC = () => {
    const { t } = useTranslation('common')
    const language = useLanguage()

    return (
        <MuiDrawer
            sx={{
                width: DRAWER_WIDTH,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: DRAWER_WIDTH,
                    boxSizing: 'border-box',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                },
            }}
            variant="permanent"
            anchor="left">
            <Box sx={{ height: HEADER_HEIGHT - 1 }}>
                <Center>
                    <Link href={`/${language}/licence-contracts`}>
                        <IconButton disableFocusRipple disableRipple sx={{ height: HEADER_HEIGHT - 1 }}>
                            <EVLogoIcon sx={{ width: 40 }} />
                        </IconButton>
                    </Link>
                </Center>
            </Box>

            <Box paddingLeft={1} paddingRight={1} marginBottom={1.5}>
                <Divider />
            </Box>

            <DrawerIcon icon={<LegalEntityIcon />} href={`/${language}/legal-entities`} title={t('legalEntities')} />
            <DrawerIcon icon={<LicenceContractIcon />} href={`/${language}/licence-contracts`} title={t('contracts')} />

            <DrawerIcon icon={<MapIcon />} href={`/${language}/licence-areas`} title={t('licenceAreas')} />

            <DrawerIcon icon={<ShopIcon />} href={`/${language}/shops`} title={t('shops')} />

            <Box flexGrow={1} />

            <Box display="flex" justifyContent="center">
                <Typography variant="subtitle1" color="#e9e9e9">
                    {config.version.slice(0, 8)}
                </Typography>
            </Box>
        </MuiDrawer>
    )
}
