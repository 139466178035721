import React from 'react'

import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material'

type BreadcrumbsStore = {
    breadcrumbs: Array<React.ReactNode>
    setBreadcrumbs: (breadcrumbs: Array<React.ReactNode>) => void
}

export const useBreadcrumbs = create<BreadcrumbsStore>()(
    devtools((set) => ({
        breadcrumbs: [],
        setBreadcrumbs: (breadcrumbs) =>
            set((_) => ({
                breadcrumbs,
            })),
    })),
)

export const Breadcrumbs: React.FC = () => {
    const breadcrumbs = useBreadcrumbs((state) => state.breadcrumbs)

    if (!(Array.isArray(breadcrumbs) && breadcrumbs.length > 0)) {
        return <div />
    }

    return <MuiBreadcrumbs separator={<NavigateNextIcon fontSize="small" />}>{breadcrumbs}</MuiBreadcrumbs>
}
