'use client'

import React from 'react'

import { Toolbar } from '@mui/material'
import { styled } from '@mui/material/styles'

import { DRAWER_WIDTH } from '../../theme'

const StyledContentContainer = styled('main', { name: 'ContentContainer' })(({ theme }) => ({
    background: theme.palette.light.lightShade40,

    flexGrow: 1,
    minHeight: '100vh',

    marginLeft: DRAWER_WIDTH,
}))

export const ContentContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <StyledContentContainer>
        <Toolbar />
        {children}
    </StyledContentContainer>
)
