import * as Sentry from '@sentry/nextjs'

import { GraphQLError, GraphQLFormattedError } from 'graphql/error'
import { useSnackbar } from 'notistack'

import { useTranslation } from '@/lib/i18n'

type HandleGraphQLErrors = (graphQLErrors: readonly GraphQLFormattedError[] | undefined) => void

type UseHandleGraphQLErrors = () => {
    handleGraphQLErrors: HandleGraphQLErrors
}

export const useHandleGraphQLErrors: UseHandleGraphQLErrors = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation('common')

    const handleGraphQLErrors: HandleGraphQLErrors = (graphQLErrors) => {
        if (!graphQLErrors) {
            return
        }

        graphQLErrors.forEach((graphQLError) => {
            const { message, locations, path } = graphQLError

            const logMessage = `[GraphQL Error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`

            console.error(logMessage)

            Sentry.captureException(new GraphQLError(logMessage), {
                extra: { graphQLError },
            })

            enqueueSnackbar(t('unexpectedError'), {
                variant: 'customErrorSnackbar',
                data: graphQLErrors,
            })
        })
    }

    return {
        handleGraphQLErrors,
    }
}
