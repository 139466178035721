import * as Sentry from '@sentry/nextjs'

import React, { useEffect } from 'react'

import { signIn } from 'next-auth/react'

import { useSession } from './use-session'
import { authBreadcrumb, isValidSession } from './utils'

type AuthenticationProps = {
    children: React.ReactNode
}

export const Authentication: React.FC<AuthenticationProps> = ({ children }) => {
    const { session } = useSession()

    useEffect(() => {
        if (!isValidSession(session)) {
            signIn('keycloak')
        } else {
            Sentry.setUser({
                claims: session.roles,
                email: session.email || undefined,
                id: session.ldap_id || undefined,
            })

            Sentry.setTag('email', session.email)

            Sentry.addBreadcrumb(authBreadcrumb({ message: 'Session', session, source: 'Authentication' }))
        }
    }, [session])

    if (!isValidSession(session)) {
        return null
    }

    return <>{children}</>
}
