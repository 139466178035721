import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const EVLogoIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 41 20">
        <path
            d="M22.492 11.028c.312-.76.469-1.68.469-2.752h1.836c0 1.625-.338 2.993-1.005 4.095.3.283.591.477.874.583s.621.156 1.009.156h.447v1.701h-.359c-.372 0-.701-.013-.984-.046s-.544-.088-.785-.177-.464-.207-.671-.359-.435-.338-.671-.561c-1.043.865-2.393 1.3-4.053 1.3-1.461 0-2.596-.334-3.402-.996s-1.207-1.6-1.207-2.807c0-.849.22-1.583.659-2.195S15.81 7.79 16.81 7.267c-.447-.523-.772-1.013-.975-1.477s-.3-.933-.3-1.41c0-.895.304-1.617.916-2.17s1.418-.827 2.419-.827 1.807.27 2.419.806.916 1.254.916 2.149c0 .671-.203 1.266-.604 1.79s-1.068 1.043-1.993 1.566l2.888 3.335h-.004zm-4.61-2.529c-.642.329-1.106.688-1.397 1.076s-.435.844-.435 1.364c0 .747.27 1.33.806 1.756s1.254.637 2.149.637c.954 0 1.752-.262 2.393-.785l-3.512-4.053-.004.004zm.692-1.992c.642-.342 1.089-.671 1.343-.984s.38-.688.38-1.119c0-.477-.123-.84-.372-1.085s-.591-.367-1.043-.367-.802.114-1.064.346-.392.557-.392.975c0 .312.084.642.257.984s.464.76.882 1.254l.008-.004z"
            fill="#e60000"
        />
        <path
            d="M.114 1.829h11.638v2.284h-9.11v4.698h7.767v2.284H2.643v5.239h9.312v2.284H.114V1.829zm25.129 0h2.731l4.724 13.99 4.724-13.99h2.575l-5.842 16.788h-3.044L25.248 1.829h-.004z"
            fill="#303030"
        />
    </SvgIcon>
)
