'use client'

import React from 'react'

import { Box, AppBar as MuiAppBar, Toolbar } from '@mui/material'

import { Breadcrumbs } from './breadcrumbs'
import { UserMenu } from './user-menu'

import { GlobalSearch } from '../../autocomplete/global-search'

import { DRAWER_WIDTH, HEADER_HEIGHT } from '../../../theme'

export const AppBar: React.FC = () => (
    <MuiAppBar
        position="fixed"
        elevation={0}
        sx={(theme) => ({
            background: theme.palette.light.white,
            boxSizing: 'border-box',
            borderBottom: `1px solid ${theme.palette.onColouredSurface.hover}`,
            minHeight: HEADER_HEIGHT,
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            marginLeft: DRAWER_WIDTH,
        })}>
        <Toolbar sx={{ display: 'flex' }}>
            <Box flex={1} marginRight="auto">
                <Breadcrumbs />
            </Box>
            <Box flex={1} display="flex" justifyContent="center">
                <GlobalSearch />
            </Box>
            <Box flex={1} marginLeft="auto" display="flex" justifyContent="flex-end">
                <UserMenu />
            </Box>
        </Toolbar>
    </MuiAppBar>
)
