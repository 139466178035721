import React from 'react'

import { CustomContentProps, SnackbarContent } from 'notistack'

import { Alert } from '@mui/material'

import { CircleCheckIcon } from '../icons'

import { palette } from '../../theme/palette'

export const SuccessSnackbar = React.forwardRef<HTMLDivElement, CustomContentProps>(({ id, ...props }, ref) => {
    const { message } = props

    return (
        <SnackbarContent ref={ref}>
            <Alert
                severity="success"
                icon={<CircleCheckIcon sx={{ fontSize: 18 }} />}
                // overide with styles defined in theme/components.ts as styles are not applied correctly
                sx={{
                    color: palette.success.main,
                    backgroundColor: palette.success.light,
                    fontSize: 14,
                    '& .MuiAlert-icon': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                }}>
                {message}
            </Alert>
        </SnackbarContent>
    )
})

SuccessSnackbar.displayName = 'SuccessSnackbar'
