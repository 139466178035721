import React from 'react'

import { SnackbarKey, useSnackbar } from 'notistack'

import Close from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'

export const CloseNotificationButton: React.FC<{ notificationId: SnackbarKey }> = ({ notificationId }) => {
    const { closeSnackbar } = useSnackbar()

    return (
        <IconButton onClick={() => closeSnackbar(notificationId)} sx={{ color: 'white' }}>
            <Close />
        </IconButton>
    )
}
