import { getOperationName } from '@apollo/client/utilities'

import {
    ActivateLicenceContractStatusDocument,
    ApproveLegalEntityDraftDocument,
    LicenceAreaUploadDocument,
} from '../../../lib/generated/graphql'

export const OperationsHandledLocally: Array<string | null> = [
    getOperationName(LicenceAreaUploadDocument),
    getOperationName(ActivateLicenceContractStatusDocument),
    getOperationName(ApproveLegalEntityDraftDocument),
]
