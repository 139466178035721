import(/* webpackMode: "eager" */ "/builds/ev/micro-services-platform/master-data/lmt/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/builds/ev/micro-services-platform/master-data/lmt/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/builds/ev/micro-services-platform/master-data/lmt/src/app/[lang]/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContentContainer"] */ "/builds/ev/micro-services-platform/master-data/lmt/src/components/layout/content-container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppBar"] */ "/builds/ev/micro-services-platform/master-data/lmt/src/components/layout/header-navigation/app-bar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SideDrawer"] */ "/builds/ev/micro-services-platform/master-data/lmt/src/components/layout/side-drawer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TranslationContext"] */ "/builds/ev/micro-services-platform/master-data/lmt/src/lib/i18n/translation-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TranslationProvider"] */ "/builds/ev/micro-services-platform/master-data/lmt/src/lib/i18n/translation-provider.tsx");
;
import(/* webpackMode: "eager" */ "/builds/ev/micro-services-platform/master-data/lmt/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/builds/ev/micro-services-platform/master-data/lmt/src/styles/info-window.css");
