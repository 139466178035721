import { ServerError, ServerParseError } from '@apollo/client'
import { NetworkError } from '@apollo/client/errors'

export const isServerError = (error: NetworkError): error is ServerError => {
    return error !== null && 'response' in error && 'statusCode' in error
}

export const isServerParseError = (error: NetworkError): error is ServerParseError => {
    return error !== null && 'bodyText' in error && 'statusCode' in error
}
