import * as Sentry from '@sentry/nextjs'

import React from 'react'

import { SentryLink } from 'apollo-link-sentry'
import { signIn } from 'next-auth/react'

import { useSession } from '@/lib/auth/use-session'
import { authBreadcrumb, isValidSession } from '@/lib/auth/utils'
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'
import { ApolloProvider as OriginalApolloProvider } from '@apollo/client/react/context/ApolloProvider'

import { useHandleGraphQLErrors } from './use-handle-graphql-errors'
import { useHandleNetworkError } from './use-handle-network-error'

import config from '../../config'
import { OperationsHandledLocally } from '../../const/errors/operations-handled-locally'

export const ApolloProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { session } = useSession()

    const { handleNetworkError } = useHandleNetworkError()
    const { handleGraphQLErrors } = useHandleGraphQLErrors()

    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
        if (OperationsHandledLocally.includes(operation.operationName)) {
            return
        }

        handleGraphQLErrors(graphQLErrors)
        handleNetworkError(networkError)
    })

    const sessionCheckLink = new ApolloLink((operation, forward) => {
        const isValid = isValidSession(session)
        const isTokenExpired = isValid && session.expires_at < Date.now()

        if (!isValid || isTokenExpired) {
            const message = !isValid ? `Session is invalid` : `Session is expired`

            Sentry.addBreadcrumb(authBreadcrumb({ message, session, source: 'sessionCheckLink' }))

            signIn('keycloak')

            return null
        }

        return forward(operation)
    })

    const httpLink = createHttpLink({
        ...config.graphql,
        fetchOptions: {
            cache: 'no-store',
        },
    })

    const removeTypenameLink = removeTypenameFromVariables()

    const client = new ApolloClient({
        link: from([new SentryLink(), removeTypenameLink, errorLink, sessionCheckLink, httpLink]),
        cache: new InMemoryCache(),
    })

    return <OriginalApolloProvider client={client}>{children}</OriginalApolloProvider>
}
